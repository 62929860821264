/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'tailwindcss/tailwind.css'

document.addEventListener('click', event => {
  if (event.target instanceof HTMLAnchorElement) {
    const url = new URL(event.target.href)
    if (url.origin === location.origin && url.hash) {
      event.preventDefault()
      history.pushState(null, '', url)

      const target = document.querySelector(`[id='${url.hash.slice(1)}']`)
      target?.scrollIntoView({ behavior: 'smooth' })
    }
  }
})
